<template>
    <div :class="{'is-required':required,'el-form-item':!thin}" class="h-100">
        <label class="el-form-item__label" :style="{width: labelWidth}">
            {{label}}{{label?':':''}}</label>
        <div class="el-form-item__content" :style="{marginLeft: labelWidth,height:divHeight}" >
            <slot></slot>
        </div>
    </div>
</template>
<script>

export default {
    name: 'front-fake-form-item',
    computed:{
      labelWidth(){
          return this.width+'px';
      },
      divHeight(){
          return this.height?this.height:'auto';
      }
    },
    props: {
        height: {
            type: String,

        },
        width: {
            type: Number,
            default() {
                return ''
            }
        },
        label: {
            type: String,
            default() {
                return ''
            }
        },
        required: {
            type: Boolean, default() {
                return false
            }},
        thin: {
            type: Boolean, default() {
                return false
            }},

    }
}
</script>
