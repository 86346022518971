<template>
    <div class="container-fluid ml-0 h-100 d-flex flex-column" v-show="content_ready">
        <el-form :model="single" ref="edit_form" label-width="100px" :rules="editRules" :disabled="!edit_mode"
                 class="flex-grow-1 overflow-hidden">
            <el-tabs v-model="tab" type="border-card" style="box-shadow: none" class="h-100">
                <el-tab-pane label="基本信息" name="1" class="position-relative">
                    <el-row class="p-2 ">
                        <el-col :span="24" :lg="12">
                            <front-single-form-editor :single="single" :item="fields.subject"
                                                      field="subject">
                            </front-single-form-editor>
                            <el-row>
                                <el-col :span="24" :lg="12">
                                    <front-single-form-editor :single="single" :item="fields.start_date"
                                                              @change="handleDateChange(1)"
                                                              field="start_date">
                                    </front-single-form-editor>
                                </el-col>
                                <el-col :span="24" :lg="12">
                                    <front-single-form-editor :single="single" :item="fields.section_id"
                                                              field="section_id">
                                    </front-single-form-editor>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24" :lg="12">
                                    <front-single-form-editor :single="single" :item="fields.start_time"
                                                              @change="handleDateChange(1)"
                                                              field="start_time">
                                    </front-single-form-editor>
                                </el-col>
                                <el-col :span="24" :lg="12">
                                    <front-single-form-editor :single="single" :item="fields.end_time"
                                                              field="end_time">
                                    </front-single-form-editor>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24" :lg="12">
                                    <front-single-form-editor :single="single" :item="fields.city_id"
                                                              field="city_id">
                                    </front-single-form-editor>
                                </el-col>
                                <el-col :span="24" :lg="12">
                                    <front-single-form-editor :single="single" :item="fields.department_id"
                                                              field="department_id">
                                    </front-single-form-editor>
                                </el-col>
                            </el-row>

                            <front-single-form-editor :single="single" :item="fields.teachers"
                                                      field="teachers">
                            </front-single-form-editor>

                            <el-row>
                                <el-col :span="24" :lg="12">
                                    <front-single-form-editor :single="single" :item="fields.passcode"
                                                              field="passcode">
                                    </front-single-form-editor>
                                </el-col>
                                <el-col :span="24" :lg="12">
                                    <front-single-form-editor :single="single" :item="fields.is_comment_disabled"
                                                              field="is_comment_disabled">
                                    </front-single-form-editor>
                                </el-col>
                            </el-row>

                        </el-col>
                        <el-col :span="24" :lg="12">
                            <el-row>
                                <el-col>
                                    <front-single-form-editor :single="single" :item="fields.picture_data"
                                                              field="picture_data">
                                    </front-single-form-editor>
                                </el-col>
                            </el-row>
                            <el-row type="flex">
                                <el-col>
                                    <front-single-form-editor :single="single" :item="fields.close_action"
                                                              field="close_action">
                                    </front-single-form-editor>
                                </el-col>
                                <el-col v-if="single.close_action === '2'">
                                    <front-single-form-editor :single="single" :item="fields.ending_picture_data"
                                                              field="ending_picture_data"
                                    >
                                    </front-single-form-editor>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24" :lg="12">
                                    <front-single-form-editor :single="single" :item="fields.source"
                                                              @change="handleSourceChange"
                                                              field="source">
                                    </front-single-form-editor>
                                </el-col>
                                <el-col :span="24" :lg="12">
                                    <front-single-form-editor :single="single" :item="fields.source2"
                                                              @change="handleSourceChange"
                                                              field="source2">
                                    </front-single-form-editor>
                                </el-col>
                            </el-row>

                            <front-fake-form-item label="回放文件" :width="100">
                                <el-autocomplete v-model="single.mp4_file"
                                                 class="w-100 mt-1"
                                                 placeholder="另外的回放文件"
                                                 :fetch-suggestions="dvrSearch"
                                                 :trigger-on-focus="false"
                                                 type="input"
                                                 size="small"
                                                 clearable>
                                </el-autocomplete>
                            </front-fake-form-item>
                            <el-row>
                                <el-col :span="24" :lg="12">
                                    <front-single-form-editor :single="single" :item="fields.is_public"
                                                              field="is_public">
                                    </front-single-form-editor>
                                </el-col>
                                <el-col :span="24" :lg="12">
                                    <front-single-form-editor :single="single" :item="fields.security_group_id"
                                                              field="security_group_id">
                                    </front-single-form-editor>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24" :lg="12">
                                    <front-single-form-editor :single="single" :item="fields.internal_ip_only"
                                                              field="internal_ip_only">
                                    </front-single-form-editor>
                                </el-col>
                                <el-col :span="24" :lg="12">
                                    <front-single-form-editor :single="single" :item="fields.assistants"
                                                              field="assistants">
                                    </front-single-form-editor>
                                </el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                </el-tab-pane>
                <el-tab-pane label="直播介绍" name="3" class="position-relative h-100 flex-column">
                    <div class="h-100 d-flex flex-column">
                        <front-fake-form-item  :width="0" class="flex-grow-1 overflow-hidden" height="100%">
                            <wang-editor v-model="single.description"/>
                        </front-fake-form-item>
                        <div class="clearfix"></div>
                        <front-single-form-editor :single="single" :item="fields.desc_picture_data"
                                                  field="desc_picture_data">
                        </front-single-form-editor>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="推流信息" name="2" class="position-relative">
                    <el-row class="p-2 ">
                        <el-row>
                            <el-col :span="24">
                                <front-fake-form-item label="推流设置1" :width="100">
                                    <div class="el-textarea el-input--small" style="height: 36px;">
                                        <el-input :value="rtmp_info[0] " readonly></el-input>
                                    </div>
                                </front-fake-form-item>
                            </el-col>
                            <el-col :span="24">
                                <front-fake-form-item label="推流设置2" :width="100">
                                    <div class="el-textarea el-input--small" style="height: 36px;">
                                        <div class="el-textarea__inner" style="min-height: 31px;">{{
                                                rtmp_info[1] || ''
                                            }}
                                        </div>
                                    </div>
                                </front-fake-form-item>
                            </el-col>
                            <el-col :span="24">
                                <front-fake-form-item label="播放地址" :width="100">
                                    <div class="el-textarea el-input--small" style="height: 36px;">
                                        <a :href="play_url_change" class="el-textarea__inner"
                                           style="min-height: 31px;">{{ play_url_change }}</a>
                                    </div>
                                </front-fake-form-item>
                            </el-col>
                            <el-col :span="24">
                                <front-fake-form-item label="监控地址" :width="100">
                                    <div class="el-textarea el-input--small" style="height: 36px;">
                                        <a :href="console_url_change" class="el-textarea__inner"
                                           style="min-height: 31px;">{{
                                                console_url_change
                                            }}</a>
                                    </div>
                                </front-fake-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="24" :lg="12">
                                <front-single-form-editor :single="single" :item="fields.uuid"
                                                          field="uuid">
                                </front-single-form-editor>
                            </el-col>
                            <el-col :span="24" :lg="12" class="el-form-item__content">
                                <div class="text-info ml-3">uuid不可随意修改，这个是节目的唯一标识</div>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="24" :lg="12">
                                <front-single-form-editor :single="single" :item="fields.dvr_file"
                                                          field="dvr_file">
                                </front-single-form-editor>
                            </el-col>
                            <el-col :span="24" :lg="12" class="el-form-item__content">
                                <el-button type="primary" size="small" plain icon="el-icon-refresh" @click="rebuildDvr"
                                           class="ml-3">
                                    重建
                                </el-button>
                            </el-col>
                        </el-row>

                    </el-row>
                </el-tab-pane>
            </el-tabs>
        </el-form>
        <el-row class="mt-2">
            <div class="mt-3">
                <el-button :loading="process.loading" type="info"
                           icon="el-icon-check" v-if="!edit_mode"
                           @click="edit_mode=!edit_mode">
                    编辑节目
                </el-button>
                <el-button :loading="process.loading" type="primary"
                           icon="el-icon-check" v-if="edit_mode"
                           @click="handleSubmit">
                    保存节目
                </el-button>
                <el-button :loading="process.loading" type="default"
                           icon="el-icon-refresh-left"
                           @click="$router.go(-1)">
                    返回节目列表
                </el-button>
            </div>
        </el-row>
    </div>
</template>
<script>

import FrontSingleFormEditor from "@/components/front-single-form-editor";
import EditMixin from "@/common/mixins/common-edit-mixin"
import FrontFakeFormItem from "@/components/front-fake-form-item";
import WangEditor from "@/components/wang-editor.vue";

export default {
    name: "program-edit",
    components: {WangEditor, FrontSingleFormEditor, FrontFakeFormItem},
    mixins: [EditMixin],
    data() {
        return {
            tab: '1',
            edit_mode: false,
            rtmp_info: [],
        }
    },
    computed: {
        console_url_change() {
            if (this.single && this.single.console_url) {
                /*
                let info = this.$urlParse(this.single.console_url);
                if (info.origin !== window.location.origin) {
                    return this.single.console_url.replace(info.origin, window.location.origin);
                }
                return this.single.console_url;
                */
                return window.location.origin + "/console/" + this.single.uuid;
            }
            return '';
        },
        play_url_change() {
            if (this.single && this.single.play_url) {
                /*
                let info = this.$urlParse(this.single.play_url);
                if (info.origin !== window.location.origin) {
                    return this.single.play_url.replace(info.origin, window.location.origin);
                }
                return this.single.play_url;
                */
                return window.location.origin + "/play/" + this.single.uuid;

            }
            return '';
        },

    },
    created() {
        this.model = "Admin.Program";
    },
    methods: {
        dvrSearch(queryString, cb) {
            if (queryString && queryString.length > 1 && queryString.length < 15) {
                this.$api('Admin.Program.SearchDvr', {q: queryString, single: this.single}, 0).then(res => {
                    cb(res.data.data.map(i => {
                        return {"value": i};
                    }));
                }).catch(function () {
                    cb([]);
                });
            } else {
                cb([]);
            }
        },
        handleDateChange(index) {
            if (index) this.single.end_date = this.single.start_date;
            else this.single.start_date = this.single.end_date;
        },
        rebuildDvr() {
            this.$api('Admin.Program.RebuildDvr', this.single, 1).then(() => {
                this.$notice.success('请等待5分钟，录像文件将会重建。');
            });
        },
        handleSourceChange() {
            this.$api(this.model + '.RtmpInfo', {stream: [this.single.source, this.single.source2]}).then(res => {
                if (res.data.success) {
                    this.rtmp_info = res.data.data;
                }
            })
        },
        processData() {
            this.handleSourceChange();
        },
    }
}
</script>

<style lang="scss">
.container-fluid {
    .el-tabs__content {
        height: calc(100% - 40px);
        overflow-y: auto;
        overflow-x: hidden;
    }

    .w-e-full-screen-container {
        z-index: 9999;
        top: 50px !important;
    }

}


</style>
