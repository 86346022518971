<template>
    <div style="border: 1px solid #ccc; height: 100%;display: flex;flex-direction: column">
        <Toolbar
            style="border-bottom: 1px solid #ccc"
            :editor="editor"
            :defaultConfig="toolbarConfig"
            :mode="mode"
        />
        <Editor
            style="overflow-y: hidden; flex:1"
            v-model="html"
            :defaultConfig="editorConfig"
            :mode="mode"
            @onChange="onChange"
            @onCreated="onCreated"
        />
    </div>
</template>
<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
export default {
    components: { Editor, Toolbar },
    props: {
        value: {
            type: String,
            default: "",
        },
        height: {
            type: String,
            default: "300px",
        },
        placeholder: {
            type: String,
            default: "请输入内容...",
        },
    },
    data() {
        return {
            editor: null,
            html: "",
            toolbarConfig: {},
            editorConfig: {
                placeholder: this.placeholder,
                MENU_CONF: {
                    //   配置上传图片
                    uploadImage: {
                        server:"",
                        fieldName: "file",
                        meta: {},
                        // 请求头
                        headers: { token: localStorage.getItem("token") },
                        timeout: 5 * 1000, // 5s 超时时间
                        //选择文件时的类型限制，默认为['image/*'] 如不想限制，则设置为 []
                        allowedFileTypes: ["image/*"],
                        maxFileSize: 30 * 1024 * 1024, //1g //设置大点 不然图片过大会报错
                        base64LimitSize: 1000000 * 1024, // 1g 以下插入 base64
                        // 自定义插入图片
                        customInsert(res, insertFn) {
                            // res 即服务端的返回结果
                            // console.log(res, "res");
                            // 从 res 中找到 url alt href ，然后插图图片
                            const {url, alt, href } = res;
                            insertFn(url, alt, href);
                        },
                    },
                    //   配置上传视频
                    uploadVideo: {
                        server:"",
                        fieldName: "file",
                        meta: {},
                        // 请求头
                        headers: { token: localStorage.getItem("token")},
                        timeout: 5 * 1000, // 5s 超时时间
                        //选择文件时的类型限制，默认为['video/*'] 如不想限制，则设置为 []
                        allowedFileTypes: ["video/*"],
                        // 自定义插入视频
                        customInsert(res, insertFn) {
                            const {url, alt, href } = res;
                            insertFn(url, alt, href);
                        },
                    },
                },
            },
            mode: "simple", // 'default' or 'simple'
        };
    },
    methods: {
        onChange() {
            this.$emit("input", this.html);
        },
        onCreated(editor) {
            this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
            this.toolbarConfig = {
                toolbarKeys: [//自定义菜单选项
                    // 菜单 key
                    "headerSelect",
                    // // 分割线
                    // // 菜单 key
                    "bold",
                    "italic",
                    "underline",
                    "color",
                    "bgColor",
                    'fontSize',
                    'lineHeight',
                    {
                       key: "group-more-style", // 必填，要以 group 开头
                       title: "更多样式", // 必填
                       iconSvg: "<i>Aa</i>", // 可选
                       menuKeys: ["through", "code", 'sub', 'sup', "divider","clearStyle"], // 下级菜单 key ，必填
                    },
                    {
                        key:"group-hyper-link",
                        title:"超链接",
                        iconSvg:"<i>link</i>",
                        menuKeys:["insertLink","editLink","unLink","viewLink"]
                    },
                    {
                        key:"group-list",
                        title:"列表",
                        iconSvg:"<i>list</i>",
                        menuKeys:["bulletedList","numberedList"]
                    },
                    {
                        key:"group-table",
                        title:"表格",
                        iconSvg:"<i>table</i>",
                        menuKeys:["insertTable","deleteTable","insertTableRow","deleteTableRow","insertTableCol","deleteTableCol","tableHeader","tableFullWidth"]
                    },
                    'indent', 'delIndent',
                    'undo','redo',
                    'fullScreen',
                ],
                excludeKeys: [
                    // 'fullScreen',
                    // 'bold', 'underline', 'italic', 'through', 'code', 'sub', 'sup', 'clearStyle', 'color', 'bgColor', 'fontSize', 'fontFamily',
                    //  'indent', 'delIndent', 'justifyLeft', 'justifyRight', 'justifyCenter', 'justifyJustify', 'lineHeight', 'insertImage', 'deleteImage',
                    //  'editImage', 'viewImageLink', 'imageWidth30', 'imageWidth50', 'imageWidth100', 'divider', 'emotion', 'insertLink', 'editLink',
                    // 'unLink', 'viewLink', 'codeBlock', 'blockquote', 'headerSelect', 'header1', 'header2', 'header3', 'header4', 'header5', 'todo',
                    // 'redo', 'undo', 'fullScreen', 'enter', 'bulletedList', 'numberedList', 'insertTable', 'deleteTable', 'insertTableRow',
                    // 'deleteTableRow', 'insertTableCol', 'deleteTableCol', 'tableHeader', 'tableFullWidth', 'insertVideo', 'uploadVideo', 'editVideoSize',
                    //  'uploadImage', 'codeSelectLang','group-more-style
                    //"sub",
                    //"sup",
                ],
            };
        },
    },
    created() {
        this.html = this.value;
    },
    mounted() {},
    beforeDestroy() {
        const editor = this.editor;
        if (editor == null) return;
        editor.destroy(); // 组件销毁时，及时销毁编辑器
    },
    watch: {
        value(val) {
            this.html = val;
        },
    },
};
</script>
<style lang="scss">
@import "~@wangeditor/editor/dist/css/style.css";
</style>

