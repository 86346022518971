<template>
    <div class="picture-upload el-input" :class="'el-input--'+size">
        <div class="el-input d-flex">
            <div class="upload-image" v-for="item in attachments" :key="item.id" :style="{backgroundColor:bgcolor}">
                <img :src="item.thumbnail" @click="showImage(item)" alt="" class="w-100 h-100">
            </div>
            <el-upload v-if="!disabled"
                class="uploader"
                :action="api_upload_image"
                :show-file-list="false"
                :on-success="handlePictureSuccess"
                :before-upload="beforePictureUpload"
                :on-progress="handlePictureProgress"
                :on-error="handleUploadError"
                :data="{type: 'image',user_token:this.user.token}"
                accept=".jpeg,.jpg,.png"
                :headers="headers">
                <div class="add" v-if="multiple || !attachments || attachments.length === 0">
                    <i class="el-icon-circle-plus"></i> {{('添加')}} {{placeholder}}
                </div>
            </el-upload>
        </div>
        <el-dialog v-picture-viewer
                   :visible.sync="view_image"
                   append-to-body>
            <div style="max-height: calc( 80vh - 63px );max-width:100%;overflow: auto;"  :style="{backgroundColor:bgcolor}">
                <img :src="single.picture" class="large-image enlarge" @click="openImage" alt="">
            </div>
            <div slot="footer">
                <el-button type="danger" size="small" class="pull-left" @click="deleteImage" v-if="!disabled"> {{('删除')}}
                </el-button>
                <el-button type="info" size="small" @click="view_image=false"> {{('返回')}}</el-button>
            </div>
        </el-dialog>
        <el-dialog v-picture-viewer v-if="process_show" :show-close="false" class="text-info">
            <i class="el-icon-loading"></i> {{('上传中...')}} {{progress}}%
        </el-dialog>
        <slot name="default"></slot>
    </div>
</template>

<script>
import {mapState,mapGetters} from "vuex"

export default {
    name: "picture-upload",
    components: {},
    model: {
        prop: 'list',
        event: 'list'
    },
    data() {
        return {
            api_upload_image: '',
            attachments: this.list,
            is_loading: false,
            view_image: false,
            headers: null,
            progress: 0,
            token: null,
            single: {},
            enlarge: false,
            process_show:false
        }
    },
    props: {
        size: {
            type: String,
            default() {
                return 'small'
            },
        },
        list: {
            type: Array,
            default() {
                return []
            },
        },
        multiple: {
            type: Boolean,
            default() {
                return false;
            }
        },
        placeholder:{
            type: String,
            default() {
                return '';
            }
        },
        bgcolor:{
            type:String,
            default(){
                return '#fff';
            }
        },
        upload_url:{
            type: String,
            default() {
                return null;
            }
        },
        accept: {
            type: String,
            default() {
                return 'image/jpeg,image/png';
            }
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            }
        }
    },
    computed: {
        ...mapState(['user']),
        ...mapGetters('preset',['english']),
    },
    watch: {
        list: {
            handler(newVal) {
                this.attachments = newVal;
            }
        }
    },
    created() {
        this.api_upload_image = this.upload_url?this.$apiUrl(this.upload_url):this.$apiUrl('User.UploadImage');
        this.token = this.$store.state.user.token;
        this.headers = {'user-token': this.token,language:this.english?'en':'zh', 'Accept': 'application/json'};
    },
    methods: {
        handlePictureSuccess(res) {
            if (res.success) { // success
                if (!this.attachments) {
                    this.attachments = [];
                }
                this.attachments.push(res.data);
                this.process_show = false;
                this.$emit('list', this.attachments);
            } else {
                this.$notice.error(res.text);
                this.process_show = false;
            }
        },
        handleUploadError(err) {
            console.log(err);
            this.progress = 0;
            this.process_show = false;
            this.$message.error('上传附件失败，仅仅支持 jpg,png 并不得超过10M。请重新上传!')
        },
        beforePictureUpload(file) {
            const sizeCheck = file.size / 1024 / 1024 <= 10;
            const split = file.name.split('.');
            const ext = split[split.length - 1].toLocaleLowerCase();
            const extCheck = (-1 !== ['jpg', 'jpeg', 'png'].indexOf(ext));

            if (!extCheck) {
                this.$notice.error(('上传附件失败，仅仅支持 jpg,png 并不得超过10M。请重新上传!'));
            } else {
                if (!sizeCheck) {
                    this.$notice.error(('上传附件失败，仅仅支持 jpg,png 并不得超过10M。请重新上传!'));
                }
            }
            return extCheck && sizeCheck;
        },
        handlePictureProgress(event) { //file,fileList
            let vm = this;
            this.process_show = true;
            let percent = Math.ceil(event.percent);
            if (percent === 100) {
                vm.progress = 99;
                vm.$nextTick(() => {
                    vm.progress = 100
                });
            } else {
                vm.progress = percent
            }
        },
        showImage(single) {
            this.single = single;
            this.view_image = true;
        },
        openImage() {
            window.open(this.single.picture, "_blank");
        },
        deleteImage() {
            const vm = this;
            this.$confirm(('确认删除该图片吗'), ('提示'), {
                confirmButtonText: ('确认'),
                cancelButtonText: ('放弃'),
                type: 'warning'
            }).then(() => {
                for (let i = 0; i < vm.attachments.length; i++) {
                    if (vm.single.id === vm.attachments[i].id) {
                        vm.attachments.splice(i, 1);
                        break;
                    }
                }
                vm.view_image = false;
            })
        }
    }
}
</script>

<style scoped lang="scss">
@import "~@/scss/global";

.picture-upload {
    .upload-image {
        cursor: zoom-in;
        margin: 0 5px;

        img {
            max-width: 36px;
            max-height: 36px;
            border: 1px solid #888888;

        }
    }

    .add {
        border: 1px dashed #eee;
        border-radius: 5px;
        padding: 0 10px;
        margin-left: 5px;
        color: $--default-light-text-color;
        cursor: pointer;

        &:hover {
            border-color: $--color-primary;
            color: $--color-primary;
            background-color: lighten($--color-primary, 35%);
        }
    }

}

.large-image {
    cursor: zoom-out;

    &.enlarge {
        max-width: 100%;
        max-height: calc(80vh - 63px);
        cursor: zoom-in;
    }
}


</style>
